<template>
  <div class="searchable-container m-auto p-3 pl-5 pr-5">
    <!-- <AppInput
      v-model="search"
      hideError
      appendIcon="search"
      placeholder="Search Name..."
    ></AppInput> -->
    <!-- Sort and Filter -->
    <!-- <div class="mt-3 d-flex justify-content-between">
      <SortBtn v-model="sort" :items="sortItems"></SortBtn>
      <FilterBtn v-model="filter" :items="filterItems">
        <slot name="filter-btn"></slot>
      </FilterBtn>
    </div> -->

    <!-- actual container -->
    <div class="row mt-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// import AppInput from '../../../shared/elements/AppInput.vue';
// import FilterBtn from '../FilterBtn.vue';
// import SortBtn from '../SortBtn.vue';
export default {
  // components: { AppInput, SortBtn, FilterBtn },
  name: 'SearchableContainer',

  props: {
    sortItems: { type: Array, default: () => [] },
    filterItems: { type: Array, default: () => [] },
  },

  data() {
    return {
      sort: '',
      filter: '',
      search: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.searchable-container {
  max-width: 1200px;
}
</style>
