<template>
  <AppCard hoverable class="p-3 shadow-40" v-on="$listeners">
    <div class="row">
      <!-- Member Image -->
      <div class="col-3">
        <AppMediaViewer
          aspect-ratio="1/1"
          width="100%"
          class="p-1 rounded-circle"
          :src="logo"
        />
      </div>
      <!-- Member Details -->
      <div class="col-7">
        <p class="m-0 font-weight-5">
          {{ company_name }}
        </p>
        <p
          v-if="members"
          class="m-0 text-body-3 text-truncate d-flex align-items-center"
        >
          <AppIcon name="user" size="12"></AppIcon>
          <b class="ml-2 mr-1 font-weight-5">{{ members }}</b>
          <span>Members</span>
        </p>
      </div>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '../../../shared/elements/AppCard.vue';
import AppIcon from '../../../shared/elements/AppIcon.vue';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';

export default {
  name: 'CompanyCard',

  components: { AppCard, AppMediaViewer, AppIcon },

  props: {
    vendor: { type: Boolean, default: false },
    company_name: { type: String, default: '' },
    members: { type: Number, default: null },
    logo: { type: String, default: null },
    userId: { type: Number, default: null },
  },

  methods: {
    confirmSuspendAccount() {
      this.$emit('confirm-suspend-account', this.userId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
