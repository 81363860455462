<template>
  <LayoutSuperadmin>
    <template v-slot:title>Vendor</template>
    <template v-slot:topbar>
      <div class="ml-2">
        <!-- Desktop Button -->
        <router-link :to="{ name: 'CompanyAdd', query: { persona: 'vendor' } }">
          <AppBtn class="text-uppercase mr-3 d-none d-md-inline-block">
            Add Company
          </AppBtn>
        </router-link>
      </div>
    </template>
    <div class="h-100">
      <!-- Tab Options -->
      <!-- <AppTabs v-model="currentTab" :items="tabOptions"></AppTabs> -->
      <router-link :to="{ name: 'CompanyAdd', query: { persona: 'vendor' } }">
        <AppBtn class="text-uppercase ml-3 d-md-none d-inline-block">
          Add Company
        </AppBtn>
      </router-link>

      <AppLoading v-if="!companies.current_page"></AppLoading>
      <!-- Member List -->
      <SearchableContainer v-else :sortItems="sortItems">
        <div class="row">
          <div
            v-for="(item, index) in companies.data"
            :key="`company-${index}`"
            class="col-12 col-md-4 mb-4"
          >
            <CompanyCard
              :company_name="item.name"
              :members="item.users_count"
              :logo="item.logo"
              :vendor="true"
              @click="
                $router.push({
                  name: 'CompanyView',
                  params: { id: item.id },
                })
              "
            ></CompanyCard>
          </div>
        </div>
        <AppPagination
          class="w-100"
          :itemsPerPage="companies.per_page"
          :total="companies.total"
          :currentPage="companies.current_page"
          @pageChange="pageChange"
        ></AppPagination>
      </SearchableContainer>
    </div>
  </LayoutSuperadmin>
</template>

<script>
// no sort yet
import CompanyCard from '@/components/Partial/UserManagement/CompanyCard.vue';
import SearchableContainer from '@/components/Partial/UserManagement/SearchableContainer.vue';
// import AppTabs from '@/shared/elements/AppTabs.vue';
import Company from '@/shared/api/Company';
import AppPagination from '@/shared/elements/AppPagination.vue';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import AppLoading from '@/shared/elements/AppLoading.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import LayoutSuperadmin from '@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
export default {
  name: 'SuperVendorManagement',

  components: {
    SearchableContainer,
    // AppTabs,
    CompanyCard,
    AppPagination,
    AppLoading,
    AppBtn,
    LayoutSuperadmin,
  },

  mixins: [_appApiHelper],

  data() {
    return {
      currentTab: 0,
      tabOptions: ['ACTIVE VENDOR', 'INACTIVE VENDOR'],
      companies: {},
      sortItems: [
        {
          value: 'asc',
          text: 'Alphabetically A-Z',
        },
        {
          value: 'desc',
          text: 'Alphabetically Z-A',
        },
      ],
    };
  },

  methods: {
    async getVendorCompany(page = 1) {
      const response = await Company.getCompanies({
        params: {
          vendor_only: 1,
          page,
          per_page: 12,
          with_member_count: 1,
        },
      });
      this.companies = response.data;
    },
    async pageChange(page) {
      this.pendingOrders = await this.getVendorCompany(page);
    },
  },

  mounted() {
    this.getVendorCompany();
  },
};
</script>

<style lang="scss" scoped></style>
